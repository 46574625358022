import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const vitals = "/neurostats/fundamental/vitals/";

// **VITALS
// Get Finance Overview = 財務分析-重要指標-財務概況
export const getOverviewData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}overview`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Per Share = 財務分析-重要指標-每股財務狀況
export const getPerShareData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}per_share`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Profitability = 財務分析-重要指標-獲利能力
export const getProfitabilityData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}profitability`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Growth Momentum
export const getGrowthMomentumData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}growth_momentum`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Operating Indicators
export const getOperatingIndicatorsData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}operating_indicators`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Financial Resilience
export const getFinancialResilienceData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}financial_resilience`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Balance Sheet
export const getBalanceSheetData = (tickerBlob: tickerBlob) => {
  const url = `${vitals}balance_sheet`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
