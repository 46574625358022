import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_value": string | number;
  "2024Q3_percentage": string | number;
  "2023Q3_value": string | number;
  "2023Q3_percentage": string | number;
  "2022Q3_value": string | number;
  "2022Q3_percentage": string | number;
  "2021Q3_value": string | number;
  "2021Q3_percentage": string | number;
  "2020Q3_value": string | number;
  "2020Q3_percentage": string | number;
  "2019Q3_value": string | number;
  "2019Q3_percentage": string | number;
  "2018Q3_value": string | number;
  "2018Q3_percentage": string | number;
}
interface GrandTotalDataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_grand_total": string;
  "2024Q3_grand_total_percentage": string;
  "2023Q3_grand_total": string;
  "2023Q3_grand_total_percentage": string;
  "2022Q3_grand_total": string;
  "2022Q3_grand_total_percentage": string;
  "2021Q3_grand_total": string;
  "2021Q3_grand_total_percentage": string;
  "2020Q3_grand_total": string;
  "2020Q3_grand_total_percentage": string;
  "2019Q3_grand_total": string;
  "2019Q3_grand_total_percentage": string;
  "2018Q3_grand_total": string;
  "2018Q3_grand_total_percentage": string;
}

const treatValue = (value: string | number) => {
  if (value !== "不適用") {
    return Number(+value / 1000).toLocaleString();
  } else {
    return value;
  }
};

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    width: 250,
    render: (text) => <strong>{text}</strong>,
  },
  {
    title: "2024年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2024Q3_value",
        key: "2024Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2024Q3_percentage",
        key: "2024Q3_percentage",
      },
    ],
  },
  {
    title: "2023年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2023Q3_value",
        key: "2023Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2023Q3_percentage",
        key: "2023Q3_percentage",
      },
    ],
  },
  {
    title: "2022年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2022Q3_value",
        key: "2022Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2022Q3_percentage",
        key: "2022Q3_percentage",
      },
    ],
  },
  {
    title: "2021年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2021Q3_value",
        key: "2021Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2021Q3_percentage",
        key: "2021Q3_percentage",
      },
    ],
  },
  {
    title: "2020年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2020Q3_value",
        key: "2020Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2020Q3_percentage",
        key: "2020Q3_percentage",
      },
    ],
  },
  {
    title: "2019年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2019Q3_value",
        key: "2019Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2019Q3_percentage",
        key: "2019Q3_percentage",
      },
    ],
  },
  {
    title: "2018年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2018Q3_value",
        key: "2018Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2018Q3_percentage",
        key: "2018Q3_percentage",
      },
    ],
  },
];

const grandTotalColumns: TableColumnsType<GrandTotalDataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    width: 250,
    render: (text) => <strong>{text}</strong>,
  },
  {
    title: "2024年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2024Q3_grand_total",
        key: "2024Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2024Q3_grand_total_percentage",
        key: "2024Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2023年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2023Q3_grand_total",
        key: "2023Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2023Q3_grand_total_percentage",
        key: "2023Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2022年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2022Q3_grand_total",
        key: "2022Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2022Q3_grand_total_percentage",
        key: "2022Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2021年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2021Q3_grand_total",
        key: "2021Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2021Q3_grand_total_percentage",
        key: "2021Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2020年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2020Q3_grand_total",
        key: "2020Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2020Q3_grand_total_percentage",
        key: "2020Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2019年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2019Q3_grand_total",
        key: "2019Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2019Q3_grand_total_percentage",
        key: "2019Q3_grand_total_percentage",
      },
    ],
  },
  {
    title: "2018年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2018Q3_grand_total",
        key: "2018Q3_grand_total",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2018Q3_grand_total_percentage",
        key: "2018Q3_grand_total_percentage",
      },
    ],
  },
];

const ProfitLossFullTable = (props: any) => {
  const { dataSource, loading } = props;
  return (
    <div className="cash-flow-full-table">
      <h2>單季</h2>
      <Table
        loading={loading}
        dataSource={dataSource[0]}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content", y: 55 * 5 }}
      />
      <h2>今年初累積至今</h2>
      <Table
        loading={loading}
        dataSource={dataSource[1]}
        columns={grandTotalColumns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content", y: 55 * 5 }}
      />
    </div>
  );
};

export default ProfitLossFullTable;
