import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const revenue = "/neurostats/fundamental/revenue/";

// **REVENUE
// 財務分析-每月營收-採用IFRSs
export const getMonthlyRevenueData = (tickerBlob: tickerBlob) => {
  const url = `${revenue}month_revenue`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// 財務分析-每月營收-單月營收
export const getRevenueOverYearsData = (tickerBlob: tickerBlob) => {
  const url = `${revenue}revenue_over_years`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// 財務分析-每月營收-單月營收解析
export const getGrandTotalOverYearsData = (tickerBlob: tickerBlob) => {
  const url = `${revenue}grand_total_over_years`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
