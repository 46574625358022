import { useEffect, useState } from "react";
import Revenue from "../layouts/Revenue";
import Vitals from "../layouts/Vitals";

import { getOverviewData } from "../server/Fundamental/vitals";

import Cashflow from "../layouts/Cashflow";
import BalanceSheet from "../layouts/BalanceSheet";
import ProfitLoss from "../layouts/ProfitLoss";

const FinanceEvaluation = (props: any) => {
  const { companyTitle, ticker, subtitle } = props;
  const [year, setYear] = useState<number>(2024);
  const [season, setSeason] = useState<any>(2);
  // Finance Vital Data
  const [overviewData, setOverviewData] = useState<any>({});

  // Finance Revenue Data
  const [loading, setLoading] = useState<boolean>(false);

  const treatData = (obj: any) => {
    const { year, season, ...rest } = obj;
    return rest;
  };

  const getOverview = () => {
    getOverviewData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setYear(res.data.year);
        setSeason(res.data.season);
        setOverviewData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOverview();
  }, []);

  return (
    <>
      {subtitle == "重要指標" && (
        <Vitals
          year={year}
          season={season}
          loading={loading}
          ticker={ticker}
          overview={overviewData}
        />
      )}
      {subtitle == "每月營收" && (
        <Revenue ticker={ticker} companyTitle={companyTitle} />
      )}
      {subtitle == "現金流量表" && (
        <Cashflow ticker={ticker} companyTitle={companyTitle} />
      )}
      {subtitle == "資產負債表" && (
        <BalanceSheet ticker={ticker} companyTitle={companyTitle} />
      )}{" "}
      {subtitle == "損益表" && (
        <ProfitLoss ticker={ticker} companyTitle={companyTitle} />
      )}
    </>
  );
};

export default FinanceEvaluation;
