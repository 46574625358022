import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { marketEvalDic } from "../../../utility/common/common";

interface DataType {
  key?: React.Key;
  title?: string;
  過去4季?: string | number;
  "103": string | number;
  "104": string | number;
  "105": string | number;
  "106": string | number;
  "107": string | number;
  "108": string | number;
  "109": string | number;
  "110": string | number;
  "111": string | number;
  "112": string | number;
}

const treatValue = (value: number | string) => {
  if (value !== "不適用") {
    return Number(value).toFixed(2);
  } else {
    return value;
  }
};

const columns: TableColumnsType<DataType> = [
  {
    title: "各式乘數",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    render: (text) => (
      <strong>{marketEvalDic[text as keyof typeof marketEvalDic]}</strong>
    ),
  },
  {
    title: "過去4季",
    dataIndex: "過去4季",
    key: "過去4季",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "103年",
    dataIndex: "103",
    key: "103",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "104年",
    dataIndex: "104",
    key: "104",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "105年",
    dataIndex: "105",
    key: "105",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "106年",
    dataIndex: "106",
    key: "106",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "107年",
    dataIndex: "107",
    key: "107",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "108年",
    dataIndex: "108",
    key: "108",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "109年",
    dataIndex: "109",
    key: "109",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "110年",
    dataIndex: "110",
    key: "110",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "111年",
    dataIndex: "111",
    key: "111",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "112年",
    dataIndex: "112",
    key: "112",
    render: (value) => <>{treatValue(value)}</>,
  },
];

const PastTenYearsTable = (props: any) => {
  const { dataSource, loading } = props;
  return (
    <div className="past-ten-years-table">
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default PastTenYearsTable;
