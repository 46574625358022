import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const balance_sheet = "/neurostats/fundamental/balance_sheet/";

// Get Balance Sheet Full Table
export const getBalanceSheetFullTableData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}full_table`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Total Asset
export const getBalanceSheetTotalAssetData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}total_asset`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Current Asset
export const getBalanceSheetCurrentAssetData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}current_asset`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Non Current Asset
export const getBalanceSheetNonCurrentAssetData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}non_current_asset`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Current Debt
export const getBalanceSheetCurrentDebtData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}current_debt`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Non Current Debt
export const getBalanceSheetNonCurrentDebtData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}non_current_debt`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Equity
export const getBalanceSheetEquityData = (tickerBlob: tickerBlob) => {
  const url = `${balance_sheet}equity`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
