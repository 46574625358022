import { useEffect, useState } from "react";
import "./style.scss";

const FinanceCard = (props: any) => {
  const { info, key, keySet, season, unit, treat, year } = props;
  const title: string = info[0];
  const data: string = info[1];

  const treatIntData = (data: any) => {
    if (data === "不適用") {
      return "不適用";
    }
    let newData = Number(data / 1000);
    return newData.toLocaleString();
  };

  return (
    <div key={key} className="finance-card">
      <h2>{keySet[title as keyof typeof keySet]}</h2>
      <span>
        {year} 年第 {season} 季
      </span>
      <h3 className="data">
        {treat ? treatIntData(data) : data}
        {treat && data !== "不適用" && unit}
      </h3>
    </div>
  );
};

export default FinanceCard;
