export const data = [
  { id: "1", title: "台幣利率比較" },
  { id: "2", title: "台幣定存利率/優惠專案" },
  { id: "3", title: "台幣活存利率/數位高利活存" },
  { id: "4", title: "美元利率比較" },
  { id: "5", title: "美金定存利率/優利定存" },
  { id: "6", title: "美金活存利率/優惠專案" },
  { id: "7", title: "外幣定存利率/優利定存" },
  { id: "8", title: "人民幣定存利率/優利定存" },
  { id: "9", title: "澳幣定存利率/優利定存" },
  { id: "10", title: "大額存款利率" },
  { id: "11", title: "定存利率" },
  { id: "12", title: "活存利率" },
  { id: "13", title: "一年期利率" },
  { id: "14", title: "一個月利率" },
  { id: "15", title: "三個月利率" },
  { id: "16", title: "薪轉戶利率" },
  { id: "17", title: "證券戶利率" },
  { id: "18", title: "公教優惠利率" },
  { id: "19", title: "郵局利率" },
  { id: "20", title: "臺灣銀行利率" },
  { id: "21", title: "合庫利率" },
  { id: "22", title: "第一銀行利率" },
  { id: "23", title: "華南銀行利率" },
  { id: "24", title: "彰化銀行利率" },
  { id: "25", title: "中國信託利率" },
  { id: "26", title: "國泰世華利率" },
  { id: "27", title: "富邦銀行利率" },
  { id: "28", title: "玉山銀行利率" },
  { id: "29", title: "將來銀行利率" },
  { id: "30", title: "LINE Bank 利率" },
];
