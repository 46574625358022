import { useEffect, useState } from "react";
import CashflowFullTable from "../components/Cashflow/CashflowFullTable";
import OperationCashflowTable from "../components/Cashflow/OperationCashflowTable";

import {
  getCashflowFullTableData,
  getCashflowOperationData,
  getCashflowInvestmentData,
  getCashflowFundraisingData,
} from "../server/Fundamental/cashflow";
import InvestmentCashflowTable from "../components/Cashflow/InvestmentCashflowTable";
import FundraisingCashflowTable from "../components/Cashflow/FundraisingCashflowTable";

interface CashflowTableDataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_value": string | number;
  "2024Q3_percentage": string | number;
  "2023Q3_value": string | number;
  "2023Q3_percentage": string | number;
  "2022Q3_value": string | number;
  "2022Q3_percentage": string | number;
  "2021Q3_value": string | number;
  "2021Q3_percentage": string | number;
  "2020Q3_value": string | number;
  "2020Q3_percentage": string | number;
  "2019Q3_value": string | number;
  "2019Q3_percentage": string | number;
  "2018Q3_value": string | number;
  "2018Q3_percentage": string | number;
}

const Cashflow = (props: any) => {
  const { ticker, companyTitle } = props;
  const [cashflowFullData, setcashflowFullData] = useState<
    CashflowTableDataType[]
  >([]);
  const [operationCashflowInfo, setOperationCashflowInfo] = useState<any>({});
  const [operationCashflowTableData, setOperationCashflowTableData] = useState<
    CashflowTableDataType[]
  >([]);
  const [cashflowInvestmentInfo, setCashflowInvestmentInfo] = useState<any>({});
  const [cashflowInvestmentTableData, setCashflowInvestmentTableData] =
    useState<CashflowTableDataType[]>([]);
  const [cashflowFundraisingInfo, setCashflowFundraisingInfo] = useState<any>(
    {}
  );
  const [cashflowFundraisingTableData, setCashflowFundraisingTableData] =
    useState<CashflowTableDataType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const getcashflowFullData = () => {
    setLoading(true);
    getCashflowFullTableData({ ticker })
      .then((res) => {
        const origArr = res.data;
        const treatedArr = origArr.map(
          (item: CashflowTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setcashflowFullData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getOperationCashflowData = () => {
    setLoading(true);
    getCashflowOperationData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setOperationCashflowInfo(res.data.content);
        setOperationCashflowTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getInvestmentCashflowData = () => {
    setLoading(true);
    getCashflowInvestmentData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setCashflowInvestmentInfo(res.data.content);
        setCashflowInvestmentTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getFundraisingCashflowData = () => {
    setLoading(true);
    getCashflowFundraisingData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setCashflowFundraisingInfo(res.data.content);
        setCashflowFundraisingTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getcashflowFullData();
    getOperationCashflowData();
    getInvestmentCashflowData();
    getFundraisingCashflowData();
  }, []);

  return (
    <>
      <div className="table-container">
        <CashflowFullTable dataSource={cashflowFullData} loading={loading} />
      </div>
      <div className="table-container">
        <OperationCashflowTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={operationCashflowInfo}
          dataSource={operationCashflowTableData}
          loading={loading}
        />
      </div>
      <div className="table-container">
        <InvestmentCashflowTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={cashflowInvestmentInfo}
          dataSource={cashflowInvestmentTableData}
          loading={loading}
        />
      </div>
      <div className="table-container">
        <FundraisingCashflowTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={cashflowFundraisingInfo}
          dataSource={cashflowFundraisingTableData}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Cashflow;
