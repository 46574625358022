export const stockTypesTwo = [
  { id: "上櫃食品工業類股", title: "上櫃食品工業類股" },
  { id: "上櫃農業科技業類股", title: "上櫃農業科技業類股" },
  { id: "上櫃塑膠工業類股", title: "上櫃塑膠工業類股" },
  { id: "上櫃紡織纖維類股", title: "上櫃紡織纖維類股" },
  { id: "上櫃電機機械類股", title: "上櫃電機機械類股" },
  { id: "上櫃電器電纜類股", title: "上櫃電器電纜類股" },
  { id: "上櫃化學工業類股", title: "上櫃化學工業類股" },
  { id: "上櫃生技醫療業類股", title: "上櫃生技醫療業類股" },
  { id: "上櫃鋼鐵工業類股", title: "上櫃鋼鐵工業類股" },
  { id: "上櫃電腦及週邊設備業類股", title: "上櫃電腦及週邊設備業類股" },
  { id: "上櫃電子零組件業類股", title: "上櫃電子零組件業類股" },
  { id: "上櫃半導體業類股", title: "上櫃半導體業類股" },
  { id: "上櫃通信網路業類股", title: "上櫃通信網路業類股" },
  { id: "上櫃光電業類股", title: "上櫃光電業類股" },
  { id: "上櫃電子通路業類股", title: "上櫃電子通路業類股" },
  { id: "上櫃其他電子業類股", title: "上櫃其他電子業類股" },
  { id: "上櫃資訊服務業類股", title: "上櫃資訊服務業類股" },
  { id: "上櫃數位雲端類股", title: "上櫃數位雲端類股" },
  { id: "上櫃觀光餐旅類股", title: "上櫃觀光餐旅類股" },
  { id: "上櫃建材營造業類股", title: "上櫃建材營造業類股" },
  { id: "上櫃航運業類股", title: "上櫃航運業類股" },
  { id: "上櫃金融保險業類股", title: "上櫃金融保險業類股" },
  { id: "上櫃居家生活類股", title: "上櫃居家生活類股" },
  { id: "上櫃運動休閒類股", title: "上櫃運動休閒類股" },
  { id: "上櫃文化創意業類股", title: "上櫃文化創意業類股" },
  { id: "上櫃綠能環保類股", title: "上櫃綠能環保類股" },
  { id: "上櫃油電燃氣業類股", title: "上櫃油電燃氣業類股" },
  { id: "上櫃其他業類股", title: "上櫃其他業類股" },
];
