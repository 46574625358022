import { useState, useEffect } from "react";
import { Stock } from "@ant-design/plots";
import { IgrFinancialChart } from "igniteui-react-charts";
import { IgrFinancialChartModule } from "igniteui-react-charts";
import StocksHistory from "./StocksHistory";

IgrFinancialChartModule.register();

const DemoStock = () => {
  const [data, setData] = useState([]);
  const [stocksData, setStocksData] = useState<any>({ data: [] });

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/antfincdn/qtQ9nYfYJe/stock-data.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  const config = {
    xField: "date",
    yField: ["open", "close", "high", "low"],
    data: data.map((i: any) => ({ ...i, date: new Date(i.trade_date) })),
  };

  const getStocksData = async () => {
    StocksHistory.getMultipleStocks().then((stocks: any[]) => {
      setStocksData(stocks);
    });
  };

  useEffect(() => {
    asyncFetch();
    getStocksData();
    console.log("config", config);
  }, []);

  return (
    <div style={{ padding: "0 1em" }}>
      <h1>ANTD Charts Stock overview</h1>
      <Stock {...config} />
      <h1>Ignite UI React</h1>
      <div className="container" style={{ height: "calc(100vh - 25px)" }}>
        <IgrFinancialChart
          width="100%"
          height="100%"
          chartType="Line"
          thickness={2}
          chartTitle="Google vs Microsoft Changes"
          subtitle="Between 2013 and 2017"
          yAxisMode="PercentChange"
          yAxisTitle="Percent Changed"
          dataSource={stocksData}
        />
      </div>
    </div>
  );
};

export default DemoStock;
