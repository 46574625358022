import { stockTypes } from "../../stocks-type.js";
import { stockTypesTwo } from "../../stocks-type-2.js";
import TileCard from "../components/TileCard";

const PopularStocks = (props: any) => {
  return (
    <div className="wrapper">
      <div className="container">
        <h1 className="section-title">台灣上市股票</h1>
        <div className="tile-container">
          {stockTypes.map((item: any) => {
            return <TileCard url={""} key={item.id} title={item.title} />;
          })}
        </div>
        <h1 className="section-title">台灣上櫃股票</h1>
        <div className="tile-container">
          {stockTypesTwo.map((item: any) => {
            return <TileCard url={""} key={item.id} title={item.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularStocks;
