export const companyListData = [
  { id: 2330, title: "台積電" },
  { id: 2317, title: "鴻海" },
  { id: 2454, title: "聯發科" },
  { id: 2308, title: "台達電" },
  { id: 2881, title: "富邦金" },
  { id: 2382, title: "廣達" },
  { id: 2303, title: "聯電" },
  { id: 2882, title: "國泰金" },
  { id: 2891, title: "中信金" },
  { id: 3711, title: "日月光投控" },
  { id: 2412, title: "中華電" },
  { id: 2886, title: "兆豐金" },
  { id: 2884, title: "玉山金" },
  { id: 1216, title: "統一" },
  { id: 2357, title: "華碩" },
  { id: 2885, title: "元大金" },
  { id: 2892, title: "第一金" },
  { id: 3034, title: "聯詠" },
  { id: 2890, title: "永豐金" },
  { id: 2327, title: "國巨" },
  { id: 5880, title: "合庫金" },
  { id: 2345, title: "智邦" },
  { id: 3231, title: "緯創" },
  { id: 2002, title: "中鋼" },
  { id: 2880, title: "華南金" },
  { id: 3008, title: "大立光" },
  { id: 2883, title: "凱基金" },
  { id: 1303, title: "南亞" },
  { id: 4938, title: "和碩" },
  { id: 2207, title: "和泰車" },
  { id: 2887, title: "台新金" },
  { id: 2379, title: "瑞昱" },
  { id: 1101, title: "台泥" },
  { id: 2603, title: "長榮" },
  { id: 2301, title: "光寶科" },
  { id: 1301, title: "台塑" },
  { id: 5871, title: "中租-KY" },
  { id: 3037, title: "欣興" },
  { id: 3045, title: "台灣大" },
  { id: 2912, title: "統一超" },
  { id: 3017, title: "奇鋐" },
  { id: 6446, title: "藥華藥" },
  { id: 4904, title: "遠傳" },
  { id: 3661, title: "世芯-KY" },
  { id: 6669, title: "緯穎" },
  { id: 1326, title: "台化" },
  { id: 5876, title: "上海商銀" },
  { id: 2395, title: "研華" },
  { id: 1590, title: "亞德客-KY" },
  { id: 6505, title: "台塑化" },
];
