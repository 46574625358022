import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const cashflow = "/neurostats/fundamental/cashflow/";

// Get Cashflow Full Table
export const getCashflowFullTableData = (tickerBlob: tickerBlob) => {
  const url = `${cashflow}full_table`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Operation
export const getCashflowOperationData = (tickerBlob: tickerBlob) => {
  const url = `${cashflow}operation`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Investment
export const getCashflowInvestmentData = (tickerBlob: tickerBlob) => {
  const url = `${cashflow}investment`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Fundraising
export const getCashflowFundraisingData = (tickerBlob: tickerBlob) => {
  const url = `${cashflow}fundraising`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
