import { Dispatch, SetStateAction } from "react";

type ProfitLossTableDataType = {
  // Define the expected structure of your data here
  [key: string]: any;
};

type APIFunction = (params: {
  ticker: string;
}) => Promise<{ data: [any, any] }>;

type TwoPartAPICallParams = {
  loadingFn: React.Dispatch<React.SetStateAction<boolean>>;
  ApiFn: APIFunction;
  infoState: React.Dispatch<React.SetStateAction<any>>;
  tableState: React.Dispatch<React.SetStateAction<any>>;
  ticker: string;
};

const twoPartAPICall = ({
  loadingFn,
  ApiFn,
  infoState,
  tableState,
  ticker,
}: TwoPartAPICallParams) => {
  loadingFn(true);

  ApiFn({ ticker })
    .then((res) => {
      const origArr = res.data[0].array;
      const origArrTwo = res.data[1].array;

      const treatedArr = origArr.map(
        (item: ProfitLossTableDataType, idx: number) => ({
          ...item,
          key: idx,
        })
      );

      const treatedArrTwo = origArrTwo.map(
        (item: ProfitLossTableDataType, idx: number) => ({
          ...item,
          key: idx,
        })
      );

      // Assuming the content to set in infoState is from the first response
      infoState(res.data[0].content || null);
      tableState([treatedArr, treatedArrTwo]);
    })
    .catch((err) => {
      console.error("Error during API call:", err);
    })
    .finally(() => {
      loadingFn(false);
    });
};

export default twoPartAPICall;
