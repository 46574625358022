import "../../App.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import { useTheme } from "../context/ThemeContext";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";

import StockOverview from "../components/Landing/StockOverview";
import IndivStock from "../pages/IndivStock";
import PopularStocks from "./PopularStocks";
import Footer from "../components/Footer";
import DemoStock from "./Test";

const Main: React.FC = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <div className={isDarkMode ? "full-wrapper wrapper-dark" : "full-wrapper"}>
      <div className={isDarkMode ? "upper upper-dark" : "upper upper-light"}>
        <Header />
      </div>
      <Routes>
        <Route path="/*" element={<StockOverview />}></Route>
        <Route path="/popular-stocks" element={<PopularStocks />}></Route>
        <Route path="/tw-stock/:tickerId" element={<IndivStock />}></Route>
        <Route path="/demo-stock" element={<DemoStock />}></Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default Main;
