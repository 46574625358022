import { useState, useEffect } from "react";
import MarketEvalCard from "../components/MarketEvaluation/MarketEvalCard";
import PastTenYearsTable from "../components/MarketEvaluation/PastTenYearsTable";

import { marketEvalDataInterface } from "../utility/interface/common";
import {
  getValuationOverviewData,
  getValuationTableData,
} from "../server/Valuation";
import { Skeleton } from "antd";
import Item from "antd/es/list/Item";

interface DataType {
  key?: React.Key;
  title?: string;
  過去4季?: string | number;
  103.0?: string | number;
  104.0?: string | number;
  105.0?: string | number;
  106.0?: string | number;
  107.0?: string | number;
  108.0?: string | number;
  109.0?: string | number;
  110.0?: string | number;
  111.0?: string | number;
  112.0?: string | number;
}

const MarketEvaluation = (props: any) => {
  const { ticker } = props;
  const [marketEvalData, setMarketEvalData] = useState<marketEvalDataInterface>(
    {}
  );
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getMarketEvalData = () => {
    setLoading(true);
    getValuationOverviewData({ ticker })
      .then((res) => {
        setMarketEvalData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTableData = () => {
    setLoading(true);
    getValuationTableData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: DataType, idx: number) => ({
          ...item,
          key: idx,
        }));
        setTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getMarketEvalData();
    getTableData();
  }, []);
  return (
    <>
      <h2>市場評價</h2>
      <div className="market-card-container">
        {loading && <Skeleton active paragraph />}
        {Object.entries(marketEvalData).map((item: Object, idx: number) => {
          return <MarketEvalCard key={idx} info={item} />;
        })}
      </div>
      <h2>過去10年表格</h2>
      <div className="table-container">
        <PastTenYearsTable dataSource={tableData} loading={loading} />
      </div>

      <p className="disclaimer">
        上表之數字均以當前最新股價、配合歷史財務數據計算得到。舉例而言，2023年之P/E(本益比)使用「最新之收盤價」÷「2023年全年每股盈餘」而得。
      </p>
    </>
  );
};

export default MarketEvaluation;
