import { useEffect, useState } from "react";
import FinanceCard from "../components/FinanceCard";

// keySets
import { financeDataOverviewTitle } from "../utility/common/common";
import { perShareDataTitle } from "../utility/common/common";
import { profitabilityDataTitle } from "../utility/common/common";
import { growthMomentumDataTitle } from "../utility/common/common";
import { operatingIndicatorsTitle } from "../utility/common/common";
import { financialResilienceDataTitle } from "../utility/common/common";
import { balanceSheetDataTitle } from "../utility/common/common";
import { Skeleton } from "antd";

import {
  getBalanceSheetData,
  getFinancialResilienceData,
  getGrowthMomentumData,
  getOperatingIndicatorsData,
  getPerShareData,
  getProfitabilityData,
} from "../server/Fundamental/vitals";

const Vitals = (props: any) => {
  const { year, ticker, season, overview, loading } = props;
  const [perShareData, setPerShareData] = useState<any>({});
  const [profitabilityData, setProfitabilityData] = useState<any>({});
  const [growthMomentumData, setGrowthMomentumData] = useState<any>({});
  const [operatingIndicatorsData, setOperatingIndicatorsData] = useState<any>(
    {}
  );
  const [financialResilienceData, setFinancialResilienceData] = useState<any>(
    {}
  );
  const [balanceSheetData, setBalanceSheetData] = useState<any>({});

  const treatData = (obj: any) => {
    const { year, season, ...rest } = obj;
    return rest;
  };

  const getPerShare = () => {
    getPerShareData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setPerShareData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getprofitability = () => {
    getProfitabilityData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setProfitabilityData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGrowthMomentum = () => {
    getGrowthMomentumData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setGrowthMomentumData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOperatingIndicators = () => {
    getOperatingIndicatorsData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setOperatingIndicatorsData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFinancialResilience = () => {
    getFinancialResilienceData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setFinancialResilienceData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBalanceSheet = () => {
    getBalanceSheetData({ ticker })
      .then((res) => {
        let financeData = res.data;
        setBalanceSheetData(treatData(financeData));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPerShare();
    getprofitability();
    getGrowthMomentum();
    getOperatingIndicators();
    getFinancialResilience();
    getBalanceSheet();
  }, []);
  return (
    <>
      <h2>財務概況</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(overview).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={financeDataOverviewTitle}
                treat={true} // 這個會動用 LocalString，自動幫數據加應對的小數點
                unit={" 千元"}
              />
            );
          })
        )}
      </div>
      <h2>每股財務狀況</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(perShareData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={perShareDataTitle}
                treat={false}
              />
            );
          })
        )}
      </div>

      <h2>獲利能力</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(profitabilityData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={profitabilityDataTitle}
                treat={false}
              />
            );
          })
        )}
      </div>

      <h2>成長動能</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(growthMomentumData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={growthMomentumDataTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>營運指標</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(operatingIndicatorsData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={operatingIndicatorsTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>財務韌性</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(financialResilienceData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={financialResilienceDataTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>資產負債表</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(balanceSheetData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={balanceSheetDataTitle}
                treat={true}
                unit={" 千元"}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default Vitals;
