import "./style.scss";
import { Skeleton, Table } from "antd";
import type { TableColumnsType } from "antd";
interface DataType {
  key?: React.Key;
  title?: number;
  grand_total?: string;
  grand_total_increment_ratio?: string;
  grand_total_YoY_1?: string;
  grand_total_YoY_3?: string;
  grand_total_YoY_5?: string;
  grand_total_YoY_10?: string;
}

const dictTitle = (title: string) => {
  let dict: any = {
    grand_total: "1~10月累積營收",
    grand_total_increment_ratio: "累積營收成長率",
    grand_total_YoY_1: "YoY",
    grand_total_YoY_3: "3年年化成長率",
    grand_total_YoY_5: "5年年化成長率",
    grand_total_YoY_10: "10年年化成長率",
  };
  return dict[title];
};

const treatValue = (value: string) => {
  if (value !== "不適用" && !value.includes("%")) {
    return Number(+value / 1000).toLocaleString();
  } else {
    return value;
  }
};

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    render: (text) => <strong>{dictTitle(text)}</strong>,
  },
  {
    title: "2014",
    dataIndex: "2014",
    key: "2014",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2015",
    dataIndex: "2015",
    key: "2015",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2016",
    dataIndex: "2016",
    key: "2016",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2017",
    dataIndex: "2017",
    key: "2017",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2018",
    dataIndex: "2018",
    key: "2018",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2019",
    dataIndex: "2019",
    key: "2019",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2020",
    dataIndex: "2020",
    key: "2020",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2021",
    dataIndex: "2021",
    key: "2021",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2022",
    dataIndex: "2022",
    key: "2022",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2023",
    dataIndex: "2023",
    key: "2023",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2024",
    dataIndex: "2024",
    key: "2024",
    render: (value) => <>{treatValue(value)}</>,
  },
];

const TotalRevGrowthRateTable = (props: any) => {
  const { companyTitle, ticker, info, dataSource, loading } = props;
  return (
    <div className="monthly-rev-growth-rate-table">
      <h2>累積營收</h2>
      <h2 className="highlight-info">
        {companyTitle} ({ticker}) {info["累積營收總結"]}
      </h2>
      <div className="comparison-chart">
        <div className="prev-year">
          <h3>與去年同期相比</h3>
          {loading ? <Skeleton active /> : <p>{info["去年同期相比"]}</p>}
        </div>
      </div>
      <h2>累積營收成長率</h2>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default TotalRevGrowthRateTable;
