import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./app/pages/Main";
import { ConfigProvider } from "antd";

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerSplitColor: "transparent",
                borderColor: "transparent",
                headerBg: "#58c2a9",
                headerColor: "#ffffff",
                cellFontSize: 14,
              },
            },
          }}
        >
          <Routes>
            <Route path="/*" element={<Main />}></Route>
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
