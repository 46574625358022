import "./style.scss";

const StockDetailsCard = (props: any) => {
  const { set } = props;

  return (
    <div className="stock-details-card">
      <ul>
        {Object.entries(set).map((item: any, idx: number) => {
          let title = item[0];
          let data = item[1];
          return (
            <li key={idx}>
              <span className="details-title">{title}</span>
              <span>{data}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StockDetailsCard;
