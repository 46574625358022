import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const profit_loss = "/neurostats/fundamental/profit_loss/";

// Get Profit Lose
export const getProfitLoseData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}profit_lose`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Revenue
export const getProfitLossRevenueData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}revenue`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Gross Profit
export const getProfitLossGrossProfitData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}gross_profit`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Gross Profit Percentage
export const getProfitLossGrossProfitPercentageData = (
  tickerBlob: tickerBlob
) => {
  const url = `${profit_loss}gross_profit_percentage`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Operating Income
export const getProfitLossOperatingIncomeData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}operating_income`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Operating Income Percentage
export const getProfitLossOperatingIncomePercentageData = (
  tickerBlob: tickerBlob
) => {
  const url = `${profit_loss}operating_income_percentage`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Net Income Before Tax
export const getProfitLossNetIncomeNeforeTaxData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}net_income_before_tax`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Net Income Before Tax Percentage
export const getProfitLossNetIncomeBeforeTaxPercentageData = (
  tickerBlob: tickerBlob
) => {
  const url = `${profit_loss}net_income_before_tax_percentage`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Net Income
export const getProfitLossGetNetIncomeData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}get_net_income`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Net Income Percentage
export const getProfitLossnetIncomePercentageData = (
  tickerBlob: tickerBlob
) => {
  const url = `${profit_loss}net_income_percentage`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// Get Eps
export const getProfitLossEPSData = (tickerBlob: tickerBlob) => {
  const url = `${profit_loss}EPS`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
