import "./style.scss";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "../../context/ThemeContext";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Space } from "antd";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const { isDarkMode } = useTheme();
  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <span onClick={() => navigate("/popular-stocks")}>
          熱門股票總覽與分析
        </span>
      ),
    },
    {
      key: "1",
      label: (
        <span onClick={() => navigate("/tw-stock/2330")}>
          台積電(2330)股票總覽
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span onClick={() => navigate("/tw-stock/3231")}>
          緯創(3231)股票總覽
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span onClick={() => navigate("/tw-stock/3037")}>
          欣興(3037)股票總覽
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span onClick={() => navigate("/tw-stock/1504")}>
          東元(1504)股票總覽
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span onClick={() => navigate("/tw-stock/8069")}>
          元太(8069)股票總覽
        </span>
      ),
    },
  ];

  return (
    <>
      <header className="header">
        <div className="nav-wrapper">
          <div className="nav-bar">
            <div className={isDarkMode ? "logo dark" : "logo"}>
              <h1 onClick={() => navigate("/")}>
                <span>
                  <img
                    className="logo-image"
                    src={
                      isDarkMode
                        ? `/images/logo-dark.png`
                        : `/images/logo-white.png`
                    }
                  />
                </span>
              </h1>
            </div>
            <div className="main-nav-wrapper">
              <ul
                className={isDarkMode ? "nav-category dark" : "nav-category"}
              ></ul>
              <div className="language">
                <span
                  className={isDarkMode ? "lang-select dark" : "lang-select"}
                >
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        股票
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
