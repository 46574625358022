import "./style.scss";
import { Skeleton, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_value": string | number;
  "2024Q3_percentage": string | number;
  "2023Q3_value": string | number;
  "2023Q3_percentage": string | number;
  "2022Q3_value": string | number;
  "2022Q3_percentage": string | number;
  "2021Q3_value": string | number;
  "2021Q3_percentage": string | number;
  "2020Q3_value": string | number;
  "2020Q3_percentage": string | number;
  "2019Q3_value": string | number;
  "2019Q3_percentage": string | number;
  "2018Q3_value": string | number;
  "2018Q3_percentage": string | number;
}

const treatValue = (value: string | number) => {
  if (value !== "不適用") {
    return Number(+value / 1000).toLocaleString();
  } else {
    return value;
  }
};

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    render: (text) => <strong>{text}</strong>,
  },
  {
    title: "2024年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2024Q3_value",
        key: "2024Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2024Q3_percentage",
        key: "2024Q3_percentage",
      },
    ],
  },
  {
    title: "2023年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2023Q3_value",
        key: "2023Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2023Q3_percentage",
        key: "2023Q3_percentage",
      },
    ],
  },
  {
    title: "2022年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2022Q3_value",
        key: "2022Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2022Q3_percentage",
        key: "2022Q3_percentage",
      },
    ],
  },
  {
    title: "2021年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2021Q3_value",
        key: "2021Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2021Q3_percentage",
        key: "2021Q3_percentage",
      },
    ],
  },
  {
    title: "2020年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2020Q3_value",
        key: "2020Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2020Q3_percentage",
        key: "2020Q3_percentage",
      },
    ],
  },
  {
    title: "2019年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2019Q3_value",
        key: "2019Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2019Q3_percentage",
        key: "2019Q3_percentage",
      },
    ],
  },
  {
    title: "2018年第三季",
    children: [
      {
        title: "金額",
        dataIndex: "2018Q3_value",
        key: "2018Q3_value",
        render: (value) => <>{treatValue(value)}</>,
      },
      {
        title: "%",
        dataIndex: "2018Q3_percentage",
        key: "2018Q3_percentage",
      },
    ],
  },
];

const BalanceSheetEquityTable = (props: any) => {
  const { companyTitle, ticker, info, dataSource, loading } = props;
  return (
    <div className="operation-cashflow-table">
      <h2>權益</h2>
      <h2 className="highlight-info">
        {companyTitle} ({ticker}) {info["權益總結"]}
      </h2>
      <div className="comparison-chart">
        <div className="prev-year">
          <h3>對比上一季</h3>
          {loading ? <Skeleton active /> : <p>{info["對比上一季"]}</p>}
        </div>
        <div className="prev-month">
          <h3>對比去年年末</h3>
          {loading ? <Skeleton active /> : <p>{info["去年年末相比"]}</p>}
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content", y: 55 * 5 }}
      />
    </div>
  );
};

export default BalanceSheetEquityTable;
