import "./style.scss";
import { useNavigate } from "react-router-dom";

const TileCard = (props: any) => {
  const { key, _id, title, url } = props;
  const navigate = useNavigate();

  const directTo = () => {
    navigate(url);
  };

  return (
    <div key={key} className="tile-card" onClick={() => directTo()}>
      <h3>{title}</h3>
    </div>
  );
};

export default TileCard;
