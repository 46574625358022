import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useTheme } from "../../context/ThemeContext";
import {
  FacebookFilled,
  LinkedinFilled,
  MailOutlined,
  XOutlined,
} from "@ant-design/icons";

import "./style.scss";

const Footer: React.FC = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <footer
        className={isDarkMode ? "footer-list footer-dark" : "footer-list"}
      >
        <div className="footer-items">
          <div className={isDarkMode ? "logo dark" : "logo"}>
            <h1 onClick={() => navigate("/")}>
              <span>
                <img width={"200px"} src={`/images/logo-white.png`} />
              </span>
            </h1>
          </div>
        </div>
        <div className="footer-items footer-copyrights">
          <ul>
            <li>Terms of Use</li>
            <li>Privacy Policy</li>
            <li>Cookie Settings</li>
          </ul>
          <span>
            &copy; {moment().format("YYYY")} NeuroStats powered by{" "}
            <a target="_blank" href="https://neurowatt.ai">
              NeuroWatt.Ai
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
