import { useEffect, useState } from "react";
import {
  getProfitLoseData,
  getProfitLossRevenueData,
  getProfitLossGrossProfitData,
  getProfitLossGrossProfitPercentageData,
  getProfitLossOperatingIncomeData,
  getProfitLossOperatingIncomePercentageData,
  getProfitLossNetIncomeNeforeTaxData,
  getProfitLossNetIncomeBeforeTaxPercentageData,
  getProfitLossGetNetIncomeData,
  getProfitLossnetIncomePercentageData,
  getProfitLossEPSData,
} from "../server/Fundamental/profitLoss";
import ProfitLossFullTable from "../components/ProfitLoss/ProfitLossFullTable";
import ProfitLossRevenueTable from "../components/ProfitLoss/ProfitLossRevenueTable";
import ProfitLossGrossProfitTable from "../components/ProfitLoss/ProfitLossGrossProfitTable";
import ProfitLossGrossProfitPerctTable from "../components/ProfitLoss/ProfitLossGrossProfitPerctTable";
import ProfitLossOperatingIncomeTable from "../components/ProfitLoss/ProfitLossOperatingIncomeTable";
import ProfitLossOperatingIncomePerctTable from "../components/ProfitLoss/ProfitLossOperatingIncomePerctTable";
import ProfitLossNetIncomeBeforeTaxTable from "../components/ProfitLoss/ProfitLossNetIncomeBeforeTaxTable";
import twoPartAPICall from "../utility/fn/twoPartAPICall";
import ProfitLossNetIncomeBeforeTaxPerctTable from "../components/ProfitLoss/ProfitLossNetIncomeBeforeTaxPerctTable";
import ProfitLossNetIncomeTable from "../components/ProfitLoss/ProfitLossNetIncomeTable";
import ProfitLossNetIncomePerctTable from "../components/ProfitLoss/ProfitLossNetIncomePerctTable";
import ProfitLossEPSTable from "../components/ProfitLoss/ProfitLossEPSTable";

interface ProfitLossTableDataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_value": string | number;
  "2024Q3_percentage": string | number;
  "2023Q3_value": string | number;
  "2023Q3_percentage": string | number;
  "2022Q3_value": string | number;
  "2022Q3_percentage": string | number;
  "2021Q3_value": string | number;
  "2021Q3_percentage": string | number;
  "2020Q3_value": string | number;
  "2020Q3_percentage": string | number;
  "2019Q3_value": string | number;
  "2019Q3_percentage": string | number;
  "2018Q3_value": string | number;
  "2018Q3_percentage": string | number;
}

const ProfitLoss = (props: any) => {
  const { ticker, companyTitle } = props;
  const [plFullData, setPLFullData] = useState<any[]>([]);
  const [plRevenueInfo, setPLRevenueInfo] = useState<any>({});
  const [plRevenueTableData, setPLRevenueTableData] = useState<any[]>([]);
  const [plGrossProfitInfo, setPLGrossProfitInfo] = useState<any>({});
  const [plGrossProfitTableData, setPLGrossProfitTableData] = useState<any[]>(
    []
  );
  const [plGrossProfitPercentageInfo, setPLGrossProfitPercentageInfo] =
    useState<any>({});
  const [
    plGrossProfitPercentageTableData,
    setPLGrossProfitPercentageTableData,
  ] = useState<any[]>([]);
  const [plOperatingIncomeInfo, setPLOperatingIncomeInfo] = useState<any>({});
  const [plOperatingIncomeTableData, setPLOperatingIncomeTableData] = useState<
    any[]
  >([]);
  const [plOperatingIncomePercentageInfo, setPLOperatingIncomePercentageInfo] =
    useState<any>({});
  const [
    plOperatingIncomePercentageTableData,
    setPLOperatingIncomePercentageTableData,
  ] = useState<any[]>([]);

  const [plNetIncomeBeforeTaxInfo, setPLNetIncomeBeforeTaxInfo] = useState<any>(
    {}
  );
  const [plNetIncomeBeforeTaxTableData, setPLNetIncomeBeforeTaxTableData] =
    useState<any[]>([]);
  const [
    plNetIncomeBeforeTaxPercentageInfo,
    setPLNetIncomeBeforeTaxPercentageInfo,
  ] = useState<any>({});
  const [
    plNetIncomeBeforeTaxPercentageTableData,
    setPLNetIncomeBeforeTaxPercentageTableData,
  ] = useState<any[]>([]);

  const [plNetIncomeInfo, setPLNetIncomeInfo] = useState<any>({});
  const [plNetIncomeTableData, setPLNetIncomeTableData] = useState<any[]>([]);
  const [plNetIncomePercentageInfo, setPLNetIncomePercentageInfo] =
    useState<any>({});
  const [plNetIncomePercentageTableData, setPLNetIncomePercentageTableData] =
    useState<any[]>([]);
  const [plEPSInfo, setPLEPSInfo] = useState<any>({});
  const [plEPSTableData, setPLEPSTableData] = useState<any[]>([]);

  // loadings

  const [plFullDataLoading, setPLFullDataLoading] = useState<boolean>(false);
  const [plRevenueTableDataLoading, setPLRevenueTableDataLoading] =
    useState<boolean>(false);
  const [plGrossProfitTableDataLoading, setPLGrossProfitTableDataLoading] =
    useState<boolean>(false);
  const [
    plGrossProfitPercentageTableDataLoading,
    setPLGrossProfitPercentageTableDataLoading,
  ] = useState<boolean>(false);
  const [
    plOperatingIncomeTableDataLoading,
    setPLOperatingIncomeTableDataLoading,
  ] = useState<boolean>(false);
  const [
    plOperatingIncomePercentageTableDataLoading,
    setPLOperatingIncomePercentageTableDataLoading,
  ] = useState<boolean>(false);
  const [plNetIncomeBeforeTaxLoading, setPLNetIncomeBeforeTaxLoading] =
    useState<boolean>(false);
  const [
    plNetIncomeBeforeTaxPercentageLoading,
    setPLNetIncomeBeforeTaxPercentageLoading,
  ] = useState<boolean>(false);
  const [plNetIncomeLoading, setPLNetIncomeLoading] = useState<boolean>(false);
  const [plNetIncomePercentageLoading, setPLNetIncomePercentageLoading] =
    useState<boolean>(false);
  const [plEPSLoading, setPLEPSLoading] = useState<boolean>(false);

  const getProfitLossFullData = () => {
    setPLFullDataLoading(true);
    getProfitLoseData({ ticker })
      .then((res) => {
        const origArr = res.data[0];
        const treatedArr = origArr.map(
          (item: ProfitLossTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        const origArrTwo = res.data[1];
        const treatedArrTwo = origArrTwo.map(
          (item: ProfitLossTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setPLFullData([treatedArr, treatedArrTwo]);
        setPLFullDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPLFullDataLoading(false);
      });
  };

  useEffect(() => {
    getProfitLossFullData();
    twoPartAPICall({
      loadingFn: setPLRevenueTableDataLoading,
      ApiFn: getProfitLossRevenueData,
      infoState: setPLRevenueInfo,
      tableState: setPLRevenueTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLGrossProfitTableDataLoading,
      ApiFn: getProfitLossGrossProfitData,
      infoState: setPLGrossProfitInfo,
      tableState: setPLGrossProfitTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLGrossProfitPercentageTableDataLoading,
      ApiFn: getProfitLossGrossProfitPercentageData,
      infoState: setPLGrossProfitPercentageInfo,
      tableState: setPLGrossProfitPercentageTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLOperatingIncomeTableDataLoading,
      ApiFn: getProfitLossOperatingIncomeData,
      infoState: setPLOperatingIncomeInfo,
      tableState: setPLOperatingIncomeTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLOperatingIncomePercentageTableDataLoading,
      ApiFn: getProfitLossOperatingIncomePercentageData,
      infoState: setPLOperatingIncomePercentageInfo,
      tableState: setPLOperatingIncomePercentageTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLNetIncomeBeforeTaxLoading,
      ApiFn: getProfitLossNetIncomeNeforeTaxData,
      infoState: setPLNetIncomeBeforeTaxInfo,
      tableState: setPLNetIncomeBeforeTaxTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLNetIncomeBeforeTaxPercentageLoading,
      ApiFn: getProfitLossNetIncomeBeforeTaxPercentageData,
      infoState: setPLNetIncomeBeforeTaxPercentageInfo,
      tableState: setPLNetIncomeBeforeTaxPercentageTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLNetIncomeLoading,
      ApiFn: getProfitLossGetNetIncomeData,
      infoState: setPLNetIncomeInfo,
      tableState: setPLNetIncomeTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLNetIncomePercentageLoading,
      ApiFn: getProfitLossnetIncomePercentageData,
      infoState: setPLNetIncomePercentageInfo,
      tableState: setPLNetIncomePercentageTableData,
      ticker,
    });
    twoPartAPICall({
      loadingFn: setPLEPSLoading,
      ApiFn: getProfitLossEPSData,
      infoState: setPLEPSInfo,
      tableState: setPLEPSTableData,
      ticker,
    });
  }, []);

  return (
    <>
      <div className="table-container">
        <ProfitLossFullTable
          dataSource={plFullData}
          loading={plFullDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossRevenueTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plRevenueInfo}
          dataSource={plRevenueTableData}
          loading={plRevenueTableDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossGrossProfitTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plGrossProfitInfo}
          dataSource={plGrossProfitTableData}
          loading={plGrossProfitTableDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossGrossProfitPerctTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plGrossProfitPercentageInfo}
          dataSource={plGrossProfitPercentageTableData}
          loading={plGrossProfitPercentageTableDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossOperatingIncomeTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plOperatingIncomeInfo}
          dataSource={plOperatingIncomeTableData}
          loading={plOperatingIncomeTableDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossOperatingIncomePerctTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plOperatingIncomePercentageInfo}
          dataSource={plOperatingIncomePercentageTableData}
          loading={plOperatingIncomePercentageTableDataLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossNetIncomeBeforeTaxTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plNetIncomeBeforeTaxInfo}
          dataSource={plNetIncomeBeforeTaxTableData}
          loading={plNetIncomeBeforeTaxLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossNetIncomeBeforeTaxPerctTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plNetIncomeBeforeTaxPercentageInfo}
          dataSource={plNetIncomeBeforeTaxPercentageTableData}
          loading={plNetIncomeBeforeTaxPercentageLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossNetIncomeTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plNetIncomeInfo}
          dataSource={plNetIncomeTableData}
          loading={plNetIncomeLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossNetIncomePerctTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plNetIncomePercentageInfo}
          dataSource={plNetIncomePercentageTableData}
          loading={plNetIncomePercentageLoading}
        />
      </div>
      <div className="table-container">
        <ProfitLossEPSTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={plEPSInfo}
          dataSource={plEPSTableData}
          loading={plEPSLoading}
        />
      </div>
    </>
  );
};

export default ProfitLoss;
