import { useEffect, useState } from "react";
import IFRSRevTable from "../components/Revenue/IFRSRevTable";
import MonthlyRevGrowthRateTable from "../components/Revenue/MonthlyRevGrowthRateTable";
import TotalRevGrowthRateTable from "../components/Revenue/TotalRevGrowthRateTable";
import {
  getGrandTotalOverYearsData,
  getMonthlyRevenueData,
  getRevenueOverYearsData,
} from "../server/Fundamental/revenue";

interface IFRSRevTableDataType {
  key?: React.Key;
  title?: string | number;
  1?: string;
  2?: string;
  3?: string;
  4?: string;
  5?: string;
  6?: string;
  7?: string;
  8?: string;
  9?: string;
  10?: string;
  11?: string;
  12?: string;
  grand_total?: string;
}

interface MonthlyRevGrowthRateTableDataType {
  key?: React.Key;
  title?: number;
  revenue?: string;
  revenue_increment_ratio?: string;
  YoY_1?: string;
  YoY_3?: string;
  YoY_5?: string;
  YoY_10?: string;
}
interface TotalRevGrowthRateTableDataType {
  key?: React.Key;
  title?: number;
  grand_total?: string;
  grand_total_increment_ratio?: string;
  grand_total_YoY_1?: string;
  grand_total_YoY_3?: string;
  grand_total_YoY_5?: string;
  grand_total_YoY_10?: string;
}

const Revenue = (props: any) => {
  const { ticker, companyTitle } = props;
  const [ifrsrevTableData, setIfrsrevTableData] = useState<
    IFRSRevTableDataType[]
  >([]);
  const [monthlyRevGrowthRateInfo, setMonthlyRevGrowthRateInfo] = useState<any>(
    {}
  );
  const [monthlyRevGrowthRateTableData, setMonthlyRevGrowthRateTableData] =
    useState<MonthlyRevGrowthRateTableDataType[]>([]);
  const [totalRevGrowthRateInfo, setTotalRevGrowthRateInfo] = useState<any>({});
  const [totalRevGrowthRateTableData, setTotalRevGrowthRateTableData] =
    useState<TotalRevGrowthRateTableDataType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const getIFRSRevTableData = () => {
    setLoading(true);
    getMonthlyRevenueData({ ticker })
      .then((res) => {
        const origArr = res.data;
        const treatedArr = origArr.map(
          (item: IFRSRevTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setIfrsrevTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getMonthlyRevGrowthRate = () => {
    setLoading(true);
    getRevenueOverYearsData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map(
          (item: IFRSRevTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setMonthlyRevGrowthRateInfo(res.data.content);
        setMonthlyRevGrowthRateTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getGrandTotalOverYears = () => {
    setLoading(true);
    getGrandTotalOverYearsData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map(
          (item: IFRSRevTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setTotalRevGrowthRateInfo(res.data.content);
        setTotalRevGrowthRateTableData(treatedArr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIFRSRevTableData();
    getMonthlyRevGrowthRate();
    getGrandTotalOverYears();
  }, []);

  return (
    <>
      <div className="table-container">
        <IFRSRevTable dataSource={ifrsrevTableData} loading={loading} />
      </div>
      <div className="table-container">
        <MonthlyRevGrowthRateTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={monthlyRevGrowthRateInfo}
          dataSource={monthlyRevGrowthRateTableData}
          loading={loading}
        />
      </div>
      <div className="table-container">
        <TotalRevGrowthRateTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={totalRevGrowthRateInfo}
          dataSource={totalRevGrowthRateTableData}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Revenue;
