export const stockTypes = [
  { id: "上市水泥工業類股", title: "上市水泥工業類股" },
  { id: "上市食品工業類股", title: "上市食品工業類股" },
  { id: "上市塑膠工業類股", title: "上市塑膠工業類股" },
  { id: "上市紡織纖維類股", title: "上市紡織纖維類股" },
  { id: "上市汽車工業類股", title: "上市汽車工業類股" },
  { id: "上市電機機械類股", title: "上市電機機械類股" },
  { id: "上市電器電纜類股", title: "上市電器電纜類股" },
  { id: "上市化學工業類股", title: "上市化學工業類股" },
  { id: "上市生技醫療業類股", title: "上市生技醫療業類股" },
  { id: "上市玻璃陶瓷類股", title: "上市玻璃陶瓷類股" },
  { id: "上市造紙工業類股", title: "上市造紙工業類股" },
  { id: "上市鋼鐵工業類股", title: "上市鋼鐵工業類股" },
  { id: "上市橡膠工業類股", title: "上市橡膠工業類股" },
  { id: "上市電腦及週邊設備業類股", title: "上市電腦及週邊設備業類股" },
  { id: "上市電子零組件業類股", title: "上市電子零組件業類股" },
  { id: "上市半導體業類股", title: "上市半導體業類股" },
  { id: "上市通信網路業類股", title: "上市通信網路業類股" },
  { id: "上市電子通路業類股", title: "上市電子通路業類股" },
  { id: "上市光電業類股", title: "上市光電業類股" },
  { id: "上市其他電子業類股", title: "上市其他電子業類股" },
  { id: "上市資訊服務業類股", title: "上市資訊服務業類股" },
  { id: "上市數位雲端類股", title: "上市數位雲端類股" },
  { id: "上市建材營造業類股", title: "上市建材營造業類股" },
  { id: "上市航運業類股", title: "上市航運業類股" },
  { id: "上市觀光餐旅類股", title: "上市觀光餐旅類股" },
  { id: "上市金融保險業類股", title: "上市金融保險業類股" },
  { id: "上市貿易百貨業類股", title: "上市貿易百貨業類股" },
  { id: "上市居家生活類股", title: "上市居家生活類股" },
  { id: "上市運動休閒類股", title: "上市運動休閒類股" },
  { id: "上市綠能環保類股", title: "上市綠能環保類股" },
  { id: "上市油電燃氣業類股", title: "上市油電燃氣業類股" },
  { id: "上市其他業類股", title: "上市其他業類股" },
];
