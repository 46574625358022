import moment from "moment";
import { Item } from "../interface/common";

export const categories: Item[] = [
  {
    id: "geopolitics",
    title: "geopolitics",
  },
  {
    id: "technology",
    title: "technology",
  },
  {
    id: "economics",
    title: "economics",
  },
  {
    id: "biomedicine",
    title: "biomedicine",
  },
  {
    id: "industry",
    title: "industry",
  },
];

export const langs: Item[] = [
  {
    id: "en_",
    title: "English",
  },
  { id: "zh_tw_", title: "繁體中文" },
  { id: "zh_cn_", title: "简体中文" },
  { id: "ja_", title: "日本語" },
  { id: "es_", title: "Español" },
  { id: "ko_", title: "한국어" },
];

export const processDate = (publishedTime: string) => {
  if (!window.navigator.userAgent.includes("Chrome")) {
    let date = publishedTime.split("UTC");
    return date[0];
  }
  return moment(publishedTime).format("MMMM DD, YYYY HH:MM");
};

export const marketEvalDic = {
  P_E: "P/E(本益比)",
  P_FCF: "P/FCF(股價自由現金流比)",
  P_B: "P/B(股價淨值比)",
  P_S: "P/S(股價營收比)",
  EV_OPI: "EV/OPI(企業價值÷營業利益)",
  EV_EBIT: "EV/EBIT(企業價值÷EBIT)",
  EV_EBITDA: "EV/EBITDA(企業價值÷EBITDA)",
  EV_S: "EV/S(企業價值÷營收)",
};

export const financeDataOverviewTitle = {
  revenue: "營業收入",
  gross_profit: "營業毛利",
  operating_income: "營業利益",
  net_income: "淨利",
  operating_cash_flow: "營業活動之現金流",
  invest_cash_flow: "投資活動之淨現金流",
  financing_cash_flow: "籌資活動之淨現金流",
};

export const perShareDataTitle = {
  revenue_per_share: "每股營收",
  gross_per_share: "每股營業毛利",
  operating_income_per_share: "每股營業利益",
  eps: "每股盈餘(EPS)",
  operating_cash_flow_per_share: "每股營業現金流",
  fcf_per_share: "每股自由現金流",
};

export const profitabilityDataTitle = {
  roa: "資產報酬率",
  roe: "股東權益報酬率",
  gross_over_assets: "營業毛利÷總資產",
  roce: "ROCE",
  gross_profit_margin: "營業毛利率",
  operation_profit_rate: "營業利益率",
  net_income_rate: "淨利率",
  operating_cash_flow_profit_rate: "營業現金流利潤率",
};

export const growthMomentumDataTitle = {
  revenue_YoY: "資產報酬率",
  gross_prof_YoY: "營業毛利年成長率",
  operating_income_YoY: "營業利益率",
  net_income_YoY: "淨利率",
  operating_cash_flow_YoY: "營業現金流利潤率",
  operating_cash_flow_per_share_YoY: "營運現金流量潤率",
};

export const operatingIndicatorsTitle = {
  dso: "應收帳款收現天數",
  account_receive_over_revenue: "應收帳款佔營收比率",
  dio: "存貨周轉天數",
  inventories_revenue_ratio: "存貨佔營收比率",
  dpo: "應付帳款付現日天數",
  cash_of_conversion_cycle: "現金循環週期",
  asset_turnover: "總資產週轉率",
  applcation_turnover: "不動產、廠房及設備週轉率",
};

export const financialResilienceDataTitle = {
  current_ratio: "流動比率",
  quick_ratio: "速動比率",
  debt_to_equity_ratio: "負債權益比率",
  net_debt_to_equity_ratio: "淨負債權益比率",
  interest_coverage_ratio: "利息保障倍數",
  debt_to_operating_cash_flow: "有息負債÷營業活動現金流",
  debt_to_free_cash_flow: "有息負債÷自由現金流",
  cash_flow_ratio: "現金流量比率",
};

export const balanceSheetDataTitle = {
  current_assets: "流動資產",
  current_liabilities: "流動負債",
  non_current_assets: "非流動資產",
  non_current_liabilities: "非流動負債",
  total_asset: "資產總額",
  total_liabilities: "負債總額",
  equity: "權益",
};
