import "./style.scss";
import { Skeleton, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key?: React.Key;
  title?: number;
  YoY_1?: string;
  YoY_3?: string;
  YoY_5?: string;
  YoY_10?: string;
  grand_total_YoY_1?: string;
  grand_total_YoY_3?: string;
  grand_total_YoY_5?: string;
  grand_total_YoY_10?: string;
}

const dictTitle = (title: string) => {
  let dict: any = {
    revenue: "當月營收",
    YoY_1: "YoY",
    YoY_3: "3年年化成長率",
    YoY_5: "5年年化成長率",
    YoY_10: "10年年化成長率",
    grand_total_YoY_1: "YoY",
    grand_total_YoY_3: "3年年化成長率",
    grand_total_YoY_5: "5年年化成長率",
    grand_total_YoY_10: "10年年化成長率",
  };
  return dict[title];
};

const columns: TableColumnsType<DataType> = [
  {
    title: "(%)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    render: (text) => <strong>{dictTitle(text)}</strong>,
  },
  {
    title: "2018年第3季",
    dataIndex: "2018Q3",
    key: "2018Q3",
  },
  {
    title: "2019年第3季",
    dataIndex: "2019Q3",
    key: "2019Q3",
  },
  {
    title: "2020年第3季",
    dataIndex: "2020Q3",
    key: "2020Q3",
  },
  {
    title: "2021年第3季",
    dataIndex: "2021Q3",
    key: "2021Q3",
  },
  {
    title: "2022年第3季",
    dataIndex: "2022Q3",
    key: "2022Q3",
  },
  {
    title: "2023年第3季",
    dataIndex: "2023Q3",
    key: "2023Q3",
  },
  {
    title: "2024年第3季",
    dataIndex: "2024Q3",
    key: "2024Q3",
  },
];

const ProfitLossGrossProfitTable = (props: any) => {
  const { companyTitle, ticker, info, dataSource, loading } = props;
  return (
    <div className="profit-loss-rev-table">
      <h2>營業毛利</h2>
      <h2 className="highlight-info">
        {companyTitle} ({ticker}) {info["單季總結"]}
      </h2>
      <div className="comparison-chart">
        <div className="prev-year">
          <h3>單季</h3>
          {loading ? <Skeleton active /> : <p>{info["單季"]}</p>}
        </div>
        <div className="prev-month">
          <h3>今年初累積至今</h3>
          {loading ? <Skeleton active /> : <p>{info["今年初累積至今"]}</p>}
        </div>
      </div>
      <h2>營業毛利成長率-單季</h2>
      <Table
        loading={loading}
        dataSource={dataSource[0]}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content", y: 55 * 5 }}
      />
      <h2>營業毛利成長率-今年初累積至今</h2>
      <Table
        loading={loading}
        dataSource={dataSource[1]}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content", y: 55 * 5 }}
      />
    </div>
  );
};

export default ProfitLossGrossProfitTable;
