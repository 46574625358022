import axiosClient from "../axios";
import { tickerBlob } from "../../utility/interface/common";

const valuation = "/neurostats/valuation/";

// **VALUATION
// 價值投資-市場評價-過去4季總攬
export const getValuationOverviewData = (tickerBlob: tickerBlob) => {
  const url = `${valuation}overview`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};

// 價值投資-市場評價-過去10年表格
export const getValuationTableData = (tickerBlob: tickerBlob) => {
  const url = `${valuation}table`;
  const body = tickerBlob;
  return axiosClient.post<any>(url, body);
};
