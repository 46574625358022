import "./style.scss";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
interface DataType {
  key: React.Key;
  title: string | number;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  11: string;
  12: string;
  grand_total: string;
}

const titleDict = (title: string | number) => {
  let treatedTitle = title;
  if (typeof title === "number") {
    treatedTitle = `${title}月`;
  } else {
    treatedTitle = "1~10月";
  }
  return treatedTitle;
};

const treatValue = (value: number | string) => {
  if (value !== "不適用") {
    return Number(+value / 1000).toLocaleString();
  } else {
    return value;
  }
};

const columns: TableColumnsType<DataType> = [
  {
    title: "(TWD千元)",
    dataIndex: "title",
    fixed: "left",
    key: "title",
    render: (text) => <strong>{titleDict(text)}</strong>,
  },
  {
    title: "2014",
    dataIndex: "2014",
    key: "2014",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2015",
    dataIndex: "2015",
    key: "2015",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2016",
    dataIndex: "2016",
    key: "2016",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2017",
    dataIndex: "2017",
    key: "2017",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2018",
    dataIndex: "2018",
    key: "2018",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2019",
    dataIndex: "2019",
    key: "2019",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2020",
    dataIndex: "2020",
    key: "2020",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2021",
    dataIndex: "2021",
    key: "2021",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2022",
    dataIndex: "2022",
    key: "2022",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2023",
    dataIndex: "2023",
    key: "2023",
    render: (value) => <>{treatValue(value)}</>,
  },
  {
    title: "2024",
    dataIndex: "2024",
    key: "2024",
    render: (value) => <>{treatValue(value)}</>,
  },
];

const IFRSRevTable = (props: any) => {
  const { dataSource, loading } = props;
  return (
    <div className="ifrs-rev-table">
      <h2>採用 IFRSs 後之月營業收入資訊</h2>
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default IFRSRevTable;
